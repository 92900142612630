import React, { Component } from 'react'

import "@ui5/webcomponents/dist/json-imports/i18n.js"

export default class Home extends Component {
    render() {
        return (
            <div>
                Giriş yapıldı
            </div>
        )
    }
}
